"use client";
import { useRouter } from "next/navigation";
import { useState } from "react";

import Label from "@/components/Label";
import { useAuth } from "@/context/AuthUserProvider";

export default function Home() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const router = useRouter();
  const { signIn } = useAuth();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(null);
    try {
      await signIn(email, password);
      router.push("/home");
    } catch (error: unknown) {
      console.error(error);
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  };

  return (
    <div className="flex min-h-screen flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-sm space-y-10">
        <div>
          <h2 className="uppercase text-center text-white text-2xl leading-9 tracking-wide ">
            Sign in to your account
          </h2>
        </div>
        <form onSubmit={onSubmit} className="space-y-6" action="#" method="POST">
          <div className="relative -space-y-px rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-0 z-10  " />
            <div>
              <Label htmlFor="email-address" className="sr-only">
                Email address
              </Label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                onChange={(event) => setEmail(event.target.value)}
                required
                className="relative block w-full mb-4 border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-80,0 sm:text-sm sm:leading-6"
                placeholder="Email address"
              />
            </div>
            <div>
              <Label htmlFor="password" className="sr-only">
                Password
              </Label>
              <input
                onChange={(event) => setPassword(event.target.value)}
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="relative block w-full border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-800 sm:text-sm sm:leading-6"
                placeholder="Password"
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center"></div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center uppercase bg-transparent border border-white px-3 py-1.5 text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
            >
              Sign in
            </button>
            {error && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <strong className="font-bold">{error}</strong>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
