import React from "react";
import { twMerge } from "tailwind-merge";

type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  children: React.ReactNode;
  className?: string;
};

const Label: React.FC<LabelProps> = ({ children, className, ...props }) => {
  return (
    <label className={twMerge("my-1 block text-xs uppercase text-[#ccc] tracking-widest", className)} {...props}>
      {children}
    </label>
  );
};

export default Label;
